import categories from "constants/categories";
import { deadlineOptions } from "constants/deadline";

import React, { useState } from "react";

import { Disclosure , RadioGroup } from '@headlessui/react'
import { PencilIcon, PlusIcon, StarIcon } from "@heroicons/react/outline";
import { DAREME_SET_FORM_TYPE } from "actions/global";
import { POLLFORM_SET_FORM_VALUE_TYPE } from "actions/pollform";
import { createPoll } from "api/poll";
import AddButton from "components/common/Buttons/Add";
import { IBaseButtonProps } from "components/common/Buttons/BaseButton";
import PrimaryButton from "components/common/Buttons/Primary";
import SecondaryButton from "components/common/Buttons/Secondary";
import BaseWithIconButton from "components/common/Buttons/WithIcon";
import { Form } from "components/common/Forms";
import BaseForm from "components/common/Forms/BaseFrom";
import BaseFileInput from "components/common/Inputs/BaseFile";
import DareMeCategorySelect from "components/common/Selects/DareMe/Category";
import DareMeDeadLineSelect, {
  IDareMeSelectArray,
} from "components/common/Selects/DareMe/DeadLine";
import DareMeConfirmModal from "components/Modals/DareMe/Confirm";
import Dare from "components/Options/Dare";
import TopSection from "components/Sections/Top";
import dayjs from "dayjs";
import { Link, navigate } from "gatsby";
import useLoading from "hooks/common/useLoading";
import usePollFormContext from "hooks/common/usePollFormContext";
import { AiFillBulb } from "react-icons/ai";
import { ValueType } from "react-select";

const NewPoll: React.FunctionComponent = () => {
  const { pollForm, pollFormDispatch } = usePollFormContext();
  const { isOpen: loading, setLoading } = useLoading();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [image, setImage] = useState(pollForm.image ? URL.createObjectURL(pollForm.image) : "");

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsConfirmOpen(true);
  };

  const handleSubmit: () => void = async () => {
    try {
    } catch (error) {
      console.log(error);
    } finally {
      setIsConfirmOpen(false);
    }
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;

    if (target.files?.length) {
      const formData = new FormData();
      
      formData.append('file', target.files[0]);
      setImage(URL.createObjectURL(target.files[0]));
      pollFormDispatch({
        type: POLLFORM_SET_FORM_VALUE_TYPE,
        payload: { 
          ...pollForm, 
          image: target.files[0],
          teaser: formData
        }
      });
    } 
  }

  const onInputChange = (option: ValueType<any, false>) => {
    pollFormDispatch({
      type: POLLFORM_SET_FORM_VALUE_TYPE,
      payload: { 
        ...pollForm, 
        [option.name]: option.value
      }
    });
  }

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    // const target = e.target as HTMLFormElement;

    try {
      setLoading(true);
      navigate("preview");
      // await createPoll({
      //   title: pollForm.title,
      //   description: "Description",
      //   category: pollForm.category as Category,
      //   options: pollForm.options.join(","),
      //   deadline: dayjs().add(pollForm.deadline,"day").toDate(),
      // });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); 
    }
  }

  return (
    <>
      <TopSection
        title={"DareMe"}
        leftComponent={true}
        rightComponent={<AiFillBulb className="text-success-600" />}
      />
      <BaseForm className="max-w-2xl mx-auto px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-8 lg:mb-9 lg:rounded-lg lg:shadow-lg">
        <div className="lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
          <div className="lg:col-start-8 lg:col-span-5 lg:row-span-1">
            <Disclosure as="div" className="hidden lg:flex flex-col gap-2 items-start">
              <Form.Select
                name="deadline"
                value={pollForm.deadline}
                selected={pollForm.deadline > 0}
                options={deadlineOptions}
                onChange={onInputChange}
              >
                {pollForm.deadline}
              </Form.Select>
              <Link to={`title?value=${pollForm.title}`}>
                <FieldButton
                  filled={pollForm.title.length > 0}
                  value={pollForm.title || "Input your title"}
                />
              </Link>
              {/* <Link to="reward">
                <FieldButton
                  filled={pollForm.reward.length > 0}
                  value="reward"
                />
              </Link> */}
              <Form.Select
                name="category"
                value={pollForm.category}
                selected={pollForm.category.length > 0}
                options={categories}
                onChange={onInputChange}
              >
                {pollForm.category}
              </Form.Select>
          </Disclosure>
        </div>

          {/* Image gallery */}
          <div className="mt-8 lg:mt-0 lg:col-start-1 lg:col-span-7 lg:row-start-1 lg:row-span-3">
            <h2 className="sr-only">Teaser</h2>

            <div className="relative grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 lg:gap-8">
                <img
                  src={image.length ? image : "https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg"}
                  alt={"teaser"}
                  className={"lg:col-span-2 lg:row-span-2 rounded-lg"}
                />
                <div className="w-full h-full px-4 py-4 absolute inset-0 flex flex-col items-stretch lg:justify-end">
                  <div className="flex-1 py-4 flex flex-col lg:hidden">
                    <div className="flex-grow flex flex-col gap-2">
                      <Form.Select
                        name="deadline"
                        value={pollForm.deadline}
                        selected={pollForm.deadline > 0}
                        options={deadlineOptions}
                        onChange={onInputChange}
                      >
                      </Form.Select>
                      <Link to={`title?value=${pollForm.title}`}>
                        <FieldButton
                          filled={pollForm.title.length > 0}
                          value={pollForm.title || "Input your title"}
                        />
                      </Link>
                      {/* <Link to="reward">
                        <FieldButton
                          filled={pollForm.reward.length > 0}
                          value="reward"
                        />
                      </Link> */}
                    </div>
                    <div className="flex flex-col">
                      <Form.Select
                        name="category"
                        value={pollForm.category}
                        selected={pollForm.category.length > 0}
                        options={categories}
                        onChange={onInputChange}
                      >
                        {pollForm.category}
                      </Form.Select>
                    </div>
                  </div>
                  {/* <Link to="/dareMe/teaser" className="relative"> */}
                  <SecondaryButton
                      className="btn-pill w-full"
                    >
                    <BaseFileInput name="teaser" onChange={onImageChange}>
                      Choose an image
                      {/* Upload a 10 sec teaser */}
                    </BaseFileInput>
                  </SecondaryButton>
                  {/* </Link> */}
              </div>
            </div>
          </div>

          <div className="lg:col-span-5 lg:row-span-2">
            <div className="h-full flex flex-col">
              {/* Options */}
              <div className="flex-grow">
                <Link to={`option/1?value=${pollForm.options[0]}`}>
                  <Dare.Default
                    id="option-1"
                    name="option-1"
                    value={pollForm.options[0]}
                    username={'owner'}
                    label={
                      pollForm.options[0].length > 0
                        ? `${pollForm.options[0]}`
                        : "Dare Option"
                    }
                    className="mt-1 border-none"
                  />
                </Link>
                <Link to={`option/2?value=${pollForm.options[1]}`}>
                  <Dare.Default
                    id="option-2"
                    name="option-2"
                    value={pollForm.options[1]}
                    label={
                      pollForm.options[1].length
                        ? `${pollForm.options[1]}`
                        : "Dare Option"
                    }
                    username={'owner'}
                    className="mt-1 border-none"
                  />
                </Link>
              </div>

              <PrimaryButton type="button" onClick={onSubmit} className="w-full mt-4 px-3 py-2" disabled={loading || Object.values(pollForm).filter(value => value?.length === 0).length > 0}>
                Preview
              </PrimaryButton>
            </div>
          </div>
        </div>
      </BaseForm>
    </>
  )
};

export default NewPoll;

interface IFieldButtonProps extends IBaseButtonProps {
  filled: boolean
}

const FieldButton: React.FunctionComponent<IFieldButtonProps> = ({ filled, children, ...props }) => {
  return (
    <BaseWithIconButton className={`btn-pill ${filled ? "btn-primary btn-fill": "btn-primary btn-outline"}`} {...props}>
      {filled ? <PencilIcon className="w-4 h-4" aria-hidden="true" /> : <PlusIcon className="w-4 h-4" aria-hidden="true"/>}
    </BaseWithIconButton>
  )
}
